<template>
  <div class="index_wrapper">
    <div style="display: flex; justify-content: space-between">
      <div class="title">新增商品</div>
      <a-button type="primary" @click="back"> 返回 </a-button>
    </div>
    <div class="content">
      <div class="subTil">商品关键信息</div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 14 }"
      >
        <div class="form">
          <a-form-model-item label="商品类别" prop="goodsTypeString">
            <a-radio-group v-model="form.goodsTypeString" button-style="solid">
              <a-radio-button value="PHYSICAL"> 实物商品 </a-radio-button>
              <a-radio-button value="VIRTUAL"> 虚拟商品 </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            ref="name"
            label="商品名称"
            prop="name"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 10 }"
          >
            <a-input
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              ref="nameInputValue"
              @keyup="nameInpNum"
            />
            <span class="inpNum">{{ inpNum }}/30</span>
          </a-form-model-item>
          <a-form-model-item
            label="选择优惠券"
            v-if="form.goodsTypeString === 'VIRTUAL'"
            prop="specification"
          >
            <div style="display: flex">
              <a-input v-model="couponShowName" disabled />
              <a-button type="primary" @click="chooseVirtualGoods('')"
                >浏览...</a-button
              >
            </div>
          </a-form-model-item>
          <a-form-model-item label="商品分类" prop="categoryId">
            <a-select
              v-model="form.categoryId"
              @change="selectClassify"
              placeholder="请选择商品分类"
            >
              <a-select-option
                v-for="(item, i) in categoryList"
                :key="i"
                :value="item.code"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="规格">
            <a-input v-model="form.specification" />
          </a-form-model-item>
          <a-form-model-item label="成本价" prop="costPrice">
            <div style="position: relative">
              <a-input v-model="form.costPrice" />
              <span style="position: absolute; right: 15px">元</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="商品原价" prop="basePrice">
            <div style="position: relative">
              <a-input v-model="form.basePrice" />
              <span style="position: absolute; right: 15px">元</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="商品折扣价" prop="discountPrice">
            <div style="position: relative">
              <a-input v-model="form.discountPrice" />
              <span
                v-if="buyType === 'CASH'"
                style="position: absolute; right: 15px"
                >元</span
              >
              <span v-else style="position: absolute; right: 15px">分</span>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item label="运费" prop="transExpense">
            <a-input v-model="form.transExpense" />
          </a-form-model-item> -->
        </div>
        <div class="subTil">商品详情信息</div>
        <a-form-model-item
          label="商品说明"
          prop="introduction"
          :labelCol="{ span: 2 }"
          :wrapperCol="{ span: 14 }"
        >
          <a-textarea
            v-model="form.introduction"
            type="textarea"
            placeholder="描述商品属性"
            rows="4"
            ref="descInputValue"
            @keyup="descpNum"
          />
          <span class="descNum">{{ descNum }}/1000</span>
        </a-form-model-item>
        <a-form-model-item
          label="商品轮播图"
          prop="imageUrls"
          :labelCol="{ span: 2 }"
          :wrapperCol="{ span: 14 }"
        >
          <div style="color: rgb(153, 153, 153)">
            图片建议尺寸<span style="color: rgb(217, 86, 114)">750*400</span
            >，每张图片不大于<span style="color: rgb(217, 86, 114)">2M</span
            >，最多可上传<span style="color: rgb(217, 86, 114)">9张</span
            >图，第一张为主图，支持jpg/png。
          </div>
          <a-upload
            list-type="picture-card"
            :beforeUpload="beforeUpload"
            :file-list="imageUrlsList"
            :customRequest="customRequestFile"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="imageUrlsList.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item
          label="商品长图"
          :labelCol="{ span: 2 }"
          :wrapperCol="{ span: 14 }"
        >
          <div style="color: rgb(153, 153, 153)">
            长图建议尺寸<span style="color: rgb(217, 86, 114)">800*1200</span
            >，图片不大于<span style="color: rgb(217, 86, 114)">2M</span>。
          </div>
          <a-upload
            list-type="picture-card"
            :file-list="detailUrls"
            :beforeUpload="beforeUpload1"
            :customRequest="customRequestFile1"
            @preview="handlePreview1"
            @change="handleChange1"
          >
            <div v-if="detailUrls.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible1"
            :footer="null"
            @cancel="handleCancel1"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
          <a-button style="margin-right: 1rem" @click="back"> 取消 </a-button>
          <a-button :disabled="disabled" type="primary" @click="onSubmit">
            确认
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import req from '../../../../utils/request.js'
import {
  getBlocClassifyStateList,
  insertBlocGoods
} from '../../../../api/goods.js'
import local from '../../../../utils/local.js'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  data() {
    const validateName = (rule, value, callback) => {
      if (this.form.couponShowName === '') {
        callback(new Error('请选择优惠券'))
        return false
      }
      callback()
    }
    const validateRule = (rule, value, callback) => {
      if (this.form.basePrice === undefined) {
        callback(new Error('请填写商品原价！'))
        return false
      }
      const num = Number(this.form.basePrice)
      if (num > 999999.99) {
        callback(new Error('商品原价不应大于999999.99'))
        return false
      }
      if (num < 0) {
        callback(new Error('商品原价不应小于0'))
        return false
      }

      callback()
    }
    const validatediscountRule = (rule, value, callback) => {
      if (this.buyType === 'CASH') {
        if (this.form.discountPrice === undefined) {
          callback(new Error('请填写商品折扣价！'))
          return false
        }
        const num = Number(this.form.discountPrice)
        if (num > 999999.99) {
          callback(new Error('商品折扣价不应大于999999.99'))
          return false
        }
        if (num < 0) {
          callback(new Error('商品原价不应小于0'))
          return false
        }
      } else {
        var num = Number(this.form.discountPrice)
        var flag = new RegExp('^[1-9][0-9]*$').test(num)
        if (!flag) {
          callback(new Error('商品折扣价应为整数'))
          return false
        }
      }
      callback()
    }
    const validatecostRule = (rule, value, callback) => {
      const num = Number(this.form.costPrice)
      if (num > 999999.99) {
        callback(new Error('成本价不应大于999999.99'))
        return false
      }
      if (num < 0) {
        callback(new Error('成本价不应小于0'))
        return false
      }
      callback()
    }
    return {
      flag: true, //  节流开关
      categoryList: [],
      imageUrlsList: [],
      detailUrls: [],
      form: {
        imageUrlsList: [],
        detailUrls: '',
        goodsTypeString: 'PHYSICAL'
      },
      previewVisible: false,
      previewVisible1: false,
      previewImage: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入商品名称！',
            trigger: 'blur'
          },
          {
            min: 0,
            max: 30,
            message: '名称长度在0-30！',
            trigger: 'blur'
          }
        ],
        specification: [
          {
            required: true,
            validator: validateName,
            trigger: 'blur'
          }
        ],
        basePrice: [
          {
            required: true,
            validator: validateRule,
            trigger: ['change', 'blur']
          }
        ],
        costPrice: [
          {
            required: true,
            validator: validatecostRule,
            trigger: ['change', 'blur']
          }
        ],
        discountPrice: [
          {
            required: true,
            validator: validatediscountRule,
            trigger: ['change', 'blur']
          }
        ],
        transExpense: [
          {
            required: true,
            message: '请填写商品运费！',
            trigger: 'blur'
          }
        ],
        categoryId: [
          {
            required: true,
            message: '请选择商品分类！',
            trigger: 'change'
          }
        ],
        type: [
          {
            type: 'array',
            required: true,
            message: '请选择配送方式！',
            trigger: 'change'
          }
        ],
        introduction: [
          {
            required: true,
            message: '请描述商品属性！',
            trigger: 'blur'
          },
          {
            min: 0,
            max: 1000,
            message: '商品描述长度限制在1000个字符以内',
            trigger: 'blur'
          }
        ],
        imageUrlsList: [
          {
            required: true,
            message: '请上传图片!',
            trigger: 'blur'
          }
        ]
      },
      descNum: 0,
      inpNum: 0,
      // 选中分类
      checkedcategory: [],
      couponShowName: '',
      isInserts: true,
      tenantId: '',
      disabled: false,
      buyType: 'CASH',
      cateList: '',
      uploadUrl: process.env.VUE_APP_API_URL_OSS,
      output: {
        subDirectory: '/mall/bloc',
        width: '960',
        quality: 1,
        type: 'ImgOutput'
      },
      isUpdate: false,
      isUpload: false
    }
  },
  created() {
    this.tenantId = local.get('blocId')
    this.parentId = local.get('blocId')
    this.getClassifyList(this.parentId)
    if (this.$route.params.browse) {
      this.form = JSON.parse(local.get('saveForm'))
      this.imageUrlsList = JSON.parse(local.get('saveImg'))
      this.detailUrls = JSON.parse(local.get('saveDetail'))
      this.checkedcategory = JSON.parse(local.get('checkedcategory'))
    }
    if (this.$route.params.conpon) {
      this.form.goodsTypeString = 'VIRTUAL'
      this.couponShowName = this.$route.params.conpon[0].couponShowName
      this.form.virtualGoodsTypeString = 'COUPON'
      this.form.virtualRelateId = this.$route.params.conpon[0].couponCode
    }
  },
  methods: {
    back() {
      this.$router.push({
        path: '/commodity/man'
      })
    },
    async getClassifyList(temp) {
      const data = await getBlocClassifyStateList({
        parentId: temp,
        mallTypeString: 'BLOC'
      })
      this.cateList = data.data
      data.data.forEach(item => {
        this.categoryList.push({
          code: item.id,
          name: item.categoryName,
          mallId: item.mallId
        })
      })
    },
    // 获取可选择的虚拟商品
    chooseVirtualGoods(info) {
      local.set('saveForm', JSON.stringify(this.form))
      local.set('saveDetail', JSON.stringify(this.detailUrls))
      local.set('saveImg', JSON.stringify(this.imageUrlsList))
      if (info) {
        this.$router.push({
          name: 'choose',
          params: { info, isInserts: this.isInserts }
        })
      } else {
        this.$router.push({
          name: 'choose',
          params: { isInserts: this.isInserts }
        })
      }
    },
    // 选择分类
    selectClassify(v) {
      this.cateList.forEach(item => {
        if (item.id === v) {
          this.buyType = item.buyTypeString
        }
      })
      this.checkedcategory = this.categoryList.filter(item => {
        return item.code === v
      })
      local.set('checkedcategory', JSON.stringify(this.checkedcategory))
    },
    // 输入框文字数量计算
    nameInpNum() {
      this.inpNum = this.$refs.nameInputValue.value
        ? this.$refs.nameInputValue.value.length
        : 0
    },
    onSubmit() {
      const self = this
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          self.disabled = true
          self.form.detailUrlsList = []
          self.form.imageUrlsList = []
          self.form.mallId = self.checkedcategory[0].mallId
          self.imageUrlsList.forEach(item => {
            self.form.imageUrlsList.push(
              item.url
            )
          })
          self.detailUrls.forEach(item => {
            self.form.detailUrlsList.push(
              item.url
            )
          })
          if (self.flag) {
            self.flag = false
            setTimeout(async function() {
              var imgFlag = true
              self.imageUrlsList.forEach(item => {
                if (item.status !== 'done') {
                  imgFlag = false
                }
              })
              if (imgFlag) {
                const data = await insertBlocGoods(self.form, self.tenantId)
                if (data.code === 0) {
                  self.$message.success(data.message)
                  self.$router.push({
                    path: '/commodity'
                  })
                }
                self.flag = true
              }
            }, 500)
            self.disabled = false
          } else {
            this.$message.info('网络开小差了,请勿频繁点击')
          }
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    // 备注文字数量计算
    descpNum() {
      this.descNum = this.$refs.descInputValue.value
        ? this.$refs.descInputValue.value.length
        : 0
    },
    // 上传图片
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload(file, UpFileList) {
      if (file.size > 2 * 1024 * 1024) {
        this.$message.warning('文件大小超过最大限度1G')
        file.status = 'error'
        return false
      }
    },
    beforeUpload1(file, UpFileList) {
      if (file.size > 2 * 1024 * 1024) {
        this.$message.warning('文件大小超过最大限度1G')
        file.status = 'error'
        return false
      }
    },
    // 获取图片宽高
    loadPic (file) {
      var that = this
      var fileData = file
      var reader = new FileReader()
      reader.onload = function (e) {
        var data = e.target.result
        var image = new Image()
        image.src = data
        image.onload = function () {
          var width = image.width
          that.output.width = width
        }
      }
      reader.readAsDataURL(fileData)
    },
    // 轮播图上传
    customRequestFile (options) {
      this.isUpload = true
      this.loadPic(options.file)
      if (options.file.size > 2 * 1024 * 1024) {
        this.$message.warning('文件大小超过最大限度1G')
        file.status = 'error'
        return false
      }
      setTimeout(() => {
        const formData = new FormData()
        formData.append('file', options.file)
        formData.append('subDir', '/mall/bloc')
        req.uploadForm(this.uploadUrl, formData).then((value) => {
          if (value.code === 'OSS-SERVER-20000') {
            // options.onSuccess(value, options.file)
            this.imageUrlsList.push({
              uid: Math.random(),
              name: 'imageList',
              status: 'done',
              url: value.data
            })
            this.isUpload = false
            this.$forceUpdate()
          }
        })
      }, 500)
    },
    handleChange({ fileList }) {
      if (!this.isUpload) {
        this.imageUrlsList = [...fileList]
      }
    },
    handleCancel1() {
      this.previewVisible = false
    },
    async handlePreview1(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 商品长图上传
    customRequestFile1 (options) {
      this.isUpdate = true
      this.loadPic(options.file)
      if (options.file.size > 2 * 1024 * 1024) {
        this.$message.warning('文件大小超过最大限度1G')
        file.status = 'error'
        return false
      }
      setTimeout(() => {
        const formData = new FormData()
        formData.append('file', options.file)
        formData.append('subDir', '/mall/bloc')
        req.uploadForm(this.uploadUrl, formData).then((value) => {
          if (value.code === 'OSS-SERVER-20000') {
            // options.onSuccess(value, options.file)
            this.detailUrls.push({
              uid: Math.random(),
              name: 'imageList',
              status: 'done',
              url: value.data
            })
            this.isUpdate = false
            this.$forceUpdate()
          }
        })
      }, 500)
    },
    handleChange1({ fileList }) {
      if (!this.isUpdate) {
        this.detailUrls = [...fileList]
      }
    }
  },
  computed: {
    headers() {
      return {
        token: local.get('token')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  .title {
    font-size: 18px;
    padding-bottom: 18px;
  }
  .subTil {
    font-size: 16px;
    padding-bottom: 1rem;
  }
  .content {
    // background-color: #fff;
    padding: 1rem 0 2.4rem 1.2rem;
    // border: 1px solid rgb(234, 234, 234);
    .form {
      display: flex;
      flex-wrap: wrap;
      .ant-form-item {
        width: 50%;
        /deep/.ant-select-selection--single {
          width: 100% !important;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.index_wrapper .content .form .ant-form-item {
  margin-bottom: 1rem !important;
}
</style>
